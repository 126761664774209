import Ready from "@/utils/ready";

const selector = '[data-ref="accordion"]';
class Accordion {
  accordion: any;
  accordionButton: any;
  accordionPanel: any;

  constructor(el: any) {
    this.accordion = el;
    this.accordionButton = el.querySelector('.button');
    this.accordionPanel = el.querySelector('.accordion__panel');
    this.init();
  }

  init() {
    this.accordionButton.addEventListener('click', () => {
      this.accordionButton.classList.toggle("is-open");
      if (this.accordionPanel.style.maxHeight){
        this.accordionPanel.style.maxHeight = null;
      } else {
        this.accordionPanel.style.maxHeight = this.accordionPanel.scrollHeight + "px";
      }
    });
  }
}

(() => {
  Ready.watch(selector, (element: any) => {
    new Accordion(element);
  });
})();
