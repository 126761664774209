














































































import {
    Vue,
    Component,
    Prop,
} from 'vue-property-decorator';
import {AppBus} from "@/const";
import axios from 'axios';
import { removeUrlParam, updateUrlParam } from '@/utils/app';

@Component
export default class JobOffer extends Vue {
    @Prop({default: ''}) private contract!: string;
    @Prop({default: ''}) private workType!: string;
    @Prop({default: ''}) private experienceLevel!: string;
    @Prop({default: ''}) private position!: string;
    @Prop({default: ''}) private location!: string;
    @Prop({default: ''}) private trips!: string;
    @Prop({default: ''}) private startDate!: string;
    @Prop({default: ''}) private salary!: string;
    @Prop({default: ''}) private company!: string;
    @Prop({default: ''}) private job!: string;
    @Prop({default: ''}) private missions!: string;
    @Prop({default: ''}) private profile!: string;
    @Prop({default: ''}) private additionalInfo!: string;
    @Prop({default: ''}) private submit!: string;
    @Prop({default: ''}) private catchphrase!: string;
    @Prop({default: ''}) private copyLink!: string;
    @Prop({default: ''}) private copied!: string;
    private isLoading: boolean = false;
    private jobIsOpen: boolean = false;
    private jobOffer: any = null;
    private offerID: any = null;
    private copyToClipboardAvailable: any = true;
    // private jobOfferPanel = document.querySelector(`[data-ref=jobOfferPanel]`);
    private jobPopup = document.querySelector(`[data-ref=popupJob]`);

    private mounted() {
        AppBus.$on('openJob', this.openJob);
        AppBus.$on('changeJob', this.changeJob);
        AppBus.$on('closeJob', this.closeJob);
        AppBus.$on('openForm', this.openForm);
    }

    private changeJob(id: number) {
        this.copyToClipboardAvailable = true;
        if (this.jobIsOpen) {
            this.openJob(id);
        }
    }

    private openJob(id: number) {
        this.jobIsOpen = true;
        this.isLoading = true;
        this.jobOffer = null;
        // this.jobOfferPanel.style.maxHeight = this.jobOfferPanel.scrollHeight + "px";
        axios.post(`/api/job-offer/${id}`)
            .then((response) => {
                this.offerID = id;
                this.jobOffer = response.data.data;
                updateUrlParam('jobOffer', this.offerID);
                this.jobIsOpen = true;
                this.isLoading = false;
            })
            .catch((error) => {
                this.closeJob();
                this.isLoading = false;
            });
    }

    private closeJob() {
        removeUrlParam('jobOffer');
        this.jobIsOpen = false;
        // this.jobOfferPanel.style.maxHeight = null;
    }

    private changeSelectValue() {
        const applyJobSelect = (document as any).getElementById('apply_job_offer');
        applyJobSelect.value = this.offerID;
    }

    private openForm() {
        this.jobPopup!.classList.toggle('popup-is-open');
        this.jobPopup!.classList.remove('popup-is-closed');
        document.body.style.overflowY = 'hidden';
        this.changeSelectValue();
    }

    private copyToClipboard() {
        const url = window.location.href;
        navigator.clipboard.writeText(url).then(
            () => this.copyToClipboardAvailable = false,
        );
    }
}
