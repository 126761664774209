import Ready from "@/utils/ready";
import { AppBus } from '@/const';

const selector = '[data-ref="listMenu"]';

class ListMenu {
    id: any = null;
    el: any = null;
    headEl: any = null;
    bodyEl: any = null;
    linksEl: any = null;
    linksContainerEl: any = null;
    nameEl: any = null;
    toggled: boolean = false;

    currentHash: string|null = null;
    currentName: string|null = null;
    constructor(element: HTMLElement) {
        this.el = element;
        this.id = this.el.getAttribute('data-ref-id');
        this.headEl = this.el.querySelector(`[data-ref="listMenuHead"][data-ref-id="${this.id}"]`);
        this.bodyEl = this.el.querySelector(`[data-ref="listMenuBody"][data-ref-id="${this.id}"]`);
        this.linksEl = this.el.querySelectorAll(`[data-ref="listMenuLink"][data-ref-id="${this.id}"]`);
        this.linksContainerEl = this.el.querySelectorAll(`[data-ref="listMenuLinkContainer"][data-ref-id="${this.id}"]`);
        this.nameEl = this.el.querySelector(`[data-ref="listMenuName"][data-ref-id="${this.id}"]`);
        this.init();
    }

    private init() {
        this.setDefaultHash();
        this.addEventListeners();
        this.showHash();
    }

    private addEventListeners() {
        this.headEl.addEventListener('click', this.toggle.bind(this));
        [].forEach.call(this.linksEl, (linkEl: HTMLElement) => {
            linkEl.addEventListener('click', (e: any) => {
                this.onLinkClick(e, linkEl);
            });
        });
        AppBus.$on('listMenu:setCurrentHash', (hash: string) => {
            this.setCurrentHash(hash);
        });
        window.addEventListener('resize', () => {
            this.checkVisibility();
        });
    }

    private checkVisibility() {
        if (this.isMobile()) {
            this.showHash();
        } else {
            this.showAllHashes();
        }
    }

    private onLinkClick(e: any, linkEl: HTMLElement) {
        const href = (linkEl.getAttribute('href') as string);
        const isHash = href.startsWith('#');
        if (isHash) {
            e.preventDefault();
            this.setCurrentHash(href);
            this.toggle();
        }
    }

    private getHashesElements() {
        return Array.from(this.linksEl)
            .filter((element: any) => {
                const href = (element.getAttribute('href') as string);
                const isHash = href.startsWith('#');
                if (isHash) {
                    return !!document.querySelector(href);
                }
                return false
            })
            .map((element: any) => document.querySelector(element.getAttribute('href')));
    }

    private setDefaultHash() {
        this.currentHash = this.currentHash || this.getHashesElements()[0] ? '#' + this.getHashesElements()[0].id : null;
        this.setCurrentNameFromHash(this.currentHash);
    }

    private setCurrentHash(hash: string) {
        this.currentHash = hash;
        this.setCurrentNameFromHash(hash);
        this.showHash();
    }

    private setCurrentNameFromHash(hash: string|null) {
        const linkEl = [].find.call(this.linksEl, (element: HTMLElement) => element.getAttribute('href') === hash);
        if (linkEl) {
            this.nameEl.innerText = (linkEl as HTMLElement).innerText;
        }
    }

    private showHash() {
        const hash = this.currentHash;
        if (this.isMobile() && hash) {
            const hashElements = this.getHashesElements();
            hashElements.forEach((element) => {
                element.style.display = 'none';
            });
            const currentHashEl = hashElements.find((element) => element.matches(hash));
            if (currentHashEl) {
                currentHashEl.style.display = 'block';
                this.setOtherLinks(hash);
            }
        }
    }

    private setOtherLinks(hash: any) {
        [].forEach.call(this.linksContainerEl, (linkContainerEl: HTMLElement) => {
            const linkEl = linkContainerEl.querySelector(`[data-ref="listMenuLink"]`);
            if (linkEl && linkEl.getAttribute('href') === hash) {
                linkContainerEl.style.display = 'none';
            } else {
                linkContainerEl.style.display = 'block';
            }
        });
    }

    private showAllHashes() {
        const hashElements = this.getHashesElements();
        hashElements.forEach((element) => {
            element.style.display = 'block';
        });
    }

    private isMobile() {
        return !!this.el.offsetParent;
    }

    private toggle() {
        if (this.toggled) {
            this.toggled = false;
            this.headEl.classList.remove('listMenu__select--active');
            this.bodyEl.style.maxHeight = "0";
        } else {
            this.toggled = true;
            this.headEl.classList.add('listMenu__select--active');
            this.bodyEl.style.maxHeight = 'none';
        }
    }
}

(() => {
    Ready.watch(selector, (element: HTMLElement) => {
        new ListMenu(element);
    });
})();
