import Ready from "@/utils/ready";
import Vue from "vue";
const selector = '[data-ref="configurator"]';

(() => {
    Ready.watch(selector, (element: HTMLElement) => {
        import('@/components/organisms/Configurator.vue').then((Configurator: any) => {
            new Vue({
                components: {
                    Configurator: Configurator.default
                },
                template: `<Configurator 
                action="${element.getAttribute('data-prop-action')}"
                transReusable="${element.getAttribute('data-prop-trans-reusable')}"
                transSubmit="${element.getAttribute('data-prop-trans-submit')}"
                locale="${element.getAttribute('data-prop-locale')}"
            />`
            }).$mount(element);
        });
    });
})();
