import Ready from "@/utils/ready";
import {AppBus} from "@/const";
import axios from 'axios';

const selector = '[data-ref="itemsTree"]';

class ItemsTree {
    id: any = null;
    el: any = null;
    categoryEl: any = null;
    resultEl: any = null;
    productId: number;
    serviceId: number;
    locale: any;
    constructor(element: HTMLElement) {
        this.el = element;
        this.id = this.el.getAttribute('data-ref-id');
        this.categoryEl = this.el.querySelectorAll(`[data-ref="itemsTreeCategory"][data-ref-id="${this.id}"]`);
        this.resultEl = this.el.querySelector(`[data-ref="itemsTreeResult"][data-ref-id="${this.id}"]`);
        this.productId = this.el.getAttribute('data-product-id');
        this.serviceId = this.el.getAttribute('data-service-id');
        this.locale = this.el.getAttribute('data-locale');
        this.init();
    }

    private init() {
        this.addEventListeners();
    }

    private addEventListeners() {
        [].forEach.call(this.categoryEl, (category: any) => {
            category.addEventListener('click', () => {
                const categoryId = category.getAttribute('data-category-id');
                AppBus.$emit('itemsTree:categoryChange', categoryId);
                this.getCategoryItems(categoryId)
                    .then(() => {
                        category.classList.add('cardIcon--active');
                    });
            })
        });
        AppBus.$on('itemsTreeMenu:categoryChange', (categoryId: any) => {
            this.getCategoryItems(categoryId)
                .then(() => {
                    const category: any = [].find.call(this.categoryEl, (categoryEl: any) => categoryEl.dataset.categoryId === categoryId);
                    if (category) {
                        category.classList.add('cardIcon--active');
                    }
                });
        });
    }
    private getCategoryItems(categoryId: any) {
        return new Promise((resolve, reject) => {
            const id = this.productId ? this.productId : this.serviceId;
            const type = this.productId ? 'product' : 'service';
            const locale = this.locale;
            axios.get(`/category/${categoryId}?id=${id}&type=${type}&locale=${locale}`)
                .then((response) => {
                    this.resultEl.innerHTML = response.data;
                    this.resetSelection();
                    resolve();
                })
                .catch((err) => reject(err));
        });
    }
    resetSelection() {
        [].forEach.call(this.categoryEl, (category: any) => {
            category.classList.remove('cardIcon--active');
        });
    }
}

(() => {
    Ready.watch(selector, (element: HTMLElement) => {
        new ItemsTree(element);
    });
})();
