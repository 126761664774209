import Ready from "@/utils/ready";
import { AppBus } from '@/const';
import JobOfferVue from '@/components/JobOffer.vue';
import Vue from 'vue';
import { getUrlParam } from '@/utils/app';

const selector = '[data-ref="jobOffer"]';

class JobOffer {
    jobOffer: any;
    id: any = null;
    jobOfferButtons: any;
    jobOfferPanel: any;
    jobOfferClose: any;

    constructor(el: any) {
        this.jobOffer = el;
        this.id = el.getAttribute('data-ref-id');
        this.jobOfferButtons = el.querySelectorAll(`[data-ref="jobOfferButton"][data-ref-id="${this.id}"`);
        this.jobOfferPanel = el.querySelector(`[data-ref="jobOfferPanel"]`);
        this.jobOfferClose = el.querySelector(`[data-ref="jobOfferClose"]`);
        this.addEventListeners();
    }

    private addEventListeners() {
        [].forEach.call(this.jobOfferButtons, (item: any) => {
            item.addEventListener('click', () => {
                const id = item.getAttribute('data-job-id');
                AppBus.$emit('openJob', Number(id));
                // this.jobOfferPanel.classList.toggle("is-open");
                // if (this.jobOfferPanel.style.maxHeight){
                //   this.jobOfferPanel.style.maxHeight = null;
                // } else {
                //   this.jobOfferPanel.style.maxHeight = this.jobOfferPanel.scrollHeight + "px";
                // }
            });
        });
        // this.jobOfferClose.addEventListener('click', () => {
        //   this.jobOfferPanel.style.maxHeight = null;
        // });
        window.addEventListener('load', () => {
            const jobOfferId = getUrlParam('jobOffer');
            if (jobOfferId) {
                AppBus.$emit('setJobSliderIndex', Number(jobOfferId));
                AppBus.$emit('openJob', Number(jobOfferId));
                AppBus.$emit('listMenu:setCurrentHash', '#jobOffers');
                window.setTimeout(() => {
                    (window as any).Scroller.scrollTo('#jobOffers')
                }, 250);
            }
        });
    }
}

(() => {
    Ready.watch(selector, (element: any) => {
        new JobOffer(element);
    });
    Ready.watch('[data-ref="jobOfferVue"]', (element: any) => {
        new Vue({
            components: {
                JobOfferVue
            },
            template: `<JobOfferVue 
                contract="${element.getAttribute('data-prop-contract')}"
                workType="${element.getAttribute('data-prop-workType')}"
                experienceLevel="${element.getAttribute('data-prop-experienceLevel')}"
                position="${element.getAttribute('data-prop-position')}"
                location="${element.getAttribute('data-prop-location')}"
                trips="${element.getAttribute('data-prop-trips')}"
                startDate="${element.getAttribute('data-prop-startDate')}"
                salary="${element.getAttribute('data-prop-salary')}"
                company="${element.getAttribute('data-prop-company')}"
                job="${element.getAttribute('data-prop-job')}"
                missions="${element.getAttribute('data-prop-missions')}"
                profile="${element.getAttribute('data-prop-profile')}"
                additionalInfo="${element.getAttribute('data-prop-additionalInfo')}"
                catchphrase="${element.getAttribute('data-prop-catchphrase')}"
                submit="${element.getAttribute('data-prop-submit')}"
                copyLink="${element.getAttribute('data-prop-copyLink')}"
                copied="${element.getAttribute('data-prop-copied')}"
            />`
        }).$mount(element);
    });

})();
