import Ready from '@/utils/ready';
import store from '@/store';

const selector = '[data-ref="popup"]';

class Popup {
    id: any = null;
    toggleButtons: any = [];
    popup: any = null;

    constructor(element: HTMLElement) {
        this.popup = element;
        this.id = this.popup.getAttribute('data-ref-id');
        this.toggleButtons = document.querySelectorAll(`[data-ref="popupToggler"][data-ref-id="${this.id}"]`);
        this.addEventListeners();
    }

    private addEventListeners() {
        if (this.popup) {
            [].forEach.call(this.toggleButtons, (togglerEl: HTMLElement) => {
                togglerEl.addEventListener('click', () => {
                    const whiteBookId = togglerEl.getAttribute('data-popup-toggler-param-white-book-id');
                    const productId = togglerEl.getAttribute('data-popup-toggler-param-product-id');
                    const contactId = togglerEl.getAttribute('data-popup-toggler-param-contact-id');
                    const referenceId = togglerEl.getAttribute('data-popup-toggler-param-reference-id');
                    const serviceId = togglerEl.getAttribute('data-popup-toggler-param-service-id');
                    if (whiteBookId) {
                        store.dispatch('setCurrentFormParams', {'whiteBookId': whiteBookId});
                    }
                    if (productId) {
                        store.dispatch('setCurrentFormParams', {'productId': productId});
                    }
                    if (contactId) {
                        store.dispatch('setCurrentFormParams', {'contactId': contactId});
                    }
                    if (referenceId) {
                        store.dispatch('setCurrentFormParams', {'referenceId': referenceId});
                    }
                    if (serviceId) {
                        store.dispatch('setCurrentFormParams', {'serviceId': serviceId});
                    }
                    if (this.popup.classList.contains('is-visible')) {
                        this.popup.classList.remove('is-visible');
                        document.body.style.overflowY = 'auto';
                    } else {
                        this.popup.classList.add('is-visible');
                        document.body.style.overflowY = 'hidden';
                    }
                });
            });
        }
    }
}

(() => {
    Ready.watch(selector, (element: HTMLElement) => {
        new Popup(element);
    });
})();
