import Ready from "@/utils/ready";

const selector = '[data-ref="globalAccordion"]';
class GlobalAccordion {
  accordion: any;
  accordionItems: any;

  constructor(el: any) {
    this.accordion = el;
    this.accordionItems = el.querySelectorAll('[data-ref="globalAccordionItem"]');
    this.init();
  }

  init() {
    [].forEach.call(this.accordionItems, (accordionItem: HTMLElement) => {
      const headEl = accordionItem.querySelector('[data-ref="globalAccordionItemHead"]');
      (headEl as HTMLElement).addEventListener('click', () => {
        this.toggleBody(accordionItem);
      });
    });
  }

  toggleBody(accordionItem: HTMLElement) {
    const isActive = accordionItem.getAttribute('data-global-accordion-item-active');
    [].forEach.call(this.accordionItems, (externalAccordionItem: HTMLElement) => {
      externalAccordionItem.setAttribute('data-global-accordion-item-active', 'false');
    });
    if (isActive == 'true') {
      accordionItem.setAttribute('data-global-accordion-item-active', 'false');
    } else {
      accordionItem.setAttribute('data-global-accordion-item-active', 'true');
    }
  }
}

(() => {
  Ready.watch(selector, (element: any) => {
    new GlobalAccordion(element);
  });
})();
