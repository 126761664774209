import Ready from '@/utils/ready';
const selector = '[data-ref="footer"]';

class Footer {
    id: any = null;
    itemSelected: string = '';
    listItems: any = [];
    contentItems: any = [];
    constructor(element: HTMLElement) {
        this.id = element.getAttribute('data-ref-id');
        this.listItems = element.querySelectorAll(`[data-ref="footerListItem"][data-ref-id="${this.id}"]`);
        this.contentItems = element.querySelectorAll(`[data-ref="footerContentItem"][data-ref-id="${this.id}"]`);
        this.addEventListeners();
    }

    private addEventListeners() {
        [].forEach.call(this.listItems, (item: any) => {
            item.addEventListener('click', (e: any) => {
                this.resetSelection();
                item.classList.add('is-active');
                this.itemSelected = e.target.dataset.footer;
                this.onChange();
            });
        })
    }

    private resetSelection() {
        [].forEach.call(this.listItems, (item: any) => {
            item.classList.remove('is-active');
        });
    }

    private onChange() {
        [].forEach.call(this.contentItems, (item: any) => {
            item.classList.remove('is-visible');
            if (item.dataset.footerContent === this.itemSelected) {
                item.classList.add('is-visible');
            }
        });
    }
}

(() => {

})();
Ready.watch(selector, (element: HTMLElement) => {
   new Footer(element);
});
