import Ready from '@/utils/ready';
const selector = '[data-ref="header"]';

class Header {
    id: any = null;
    toggleButton: any = null;
    menuResponsive: any = null;
    constructor(element: HTMLElement) {
        this.id = element.getAttribute('data-ref-id');
        this.toggleButton = document.querySelector(`[data-ref="headerResponsiveMenuToggler"][data-ref-id="${this.id}"]`);
        this.menuResponsive = document.querySelector(`[data-ref="headerResponsiveMenu"][data-ref-id="${this.id}"]`);
        this.addEventListeners();
    }

    private addEventListeners() {
        if (this.toggleButton && this.menuResponsive) {
            this.toggleButton.addEventListener('click', () => {
                this.menuResponsive.classList.toggle('is-visible');
            });
        }
    }
}

(() => {
    Ready.watch(selector, (element: HTMLElement) => {
        new Header(element);
    });
})();
