import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentFormParams: null,
    formsInsertedCounter: 0,
  },
  mutations: {
    setCurrentFormParams(state, value) {
      state.currentFormParams = value;
    },
    setFormsInsertedCounter(state) {
      state.formsInsertedCounter++;
    },
  },
  actions: {
    setCurrentFormParams({commit}, value) {
      commit('setCurrentFormParams', value);
    },
    setFormsInsertedCounter({commit}) {
      commit('setFormsInsertedCounter');
    },
  },
});
