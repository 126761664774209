export default class ImagePicker {

    imageSelected: number = 0;
    firstClick: boolean = false;

    constructor(sliders: any) {
        [].forEach.call(sliders, (slider: any) => {
            const galleryImages = slider.querySelectorAll('.js-slider-gallery');
            const containerImages = slider.querySelectorAll('.js-slider-image');
            if (!this.firstClick) {
                if (containerImages.length > 0) {
                    containerImages[0].classList.add('is-visible');
                }
                if (galleryImages.length > 0) {
                    galleryImages[0].classList.add('active');
                }
            }
            if (galleryImages.length > 0) {
                [].forEach.call(galleryImages, (galleryImage: any) => {
                    galleryImage.addEventListener('click', () => {
                        this.firstClick = true;
                        this.imageSelected = galleryImage.dataset.id;
                        [].forEach.call(galleryImages, (galleryImage: any) => {
                            galleryImage.classList.remove('active');
                        });
                        [].forEach.call(containerImages, (containerImage: any) => {
                            containerImage.classList.remove('is-visible');
                            if (galleryImage.dataset.id === containerImage.dataset.id) {
                                containerImage.classList.add('is-visible');
                                galleryImage.classList.add('active');
                            }
                        })
                    });
                });
            }
        });
    }
}

new ImagePicker(document.querySelectorAll('.js-image-picker'));
