export const getNavigationOffset = () => {
    return ['[data-ref="header"]']
        .map((selector) => {
            const el: HTMLElement | null = document.querySelector(selector);
            return el ? el.offsetHeight : 0;
        })
        .reduce((accumulator, currentValue) => accumulator + currentValue);
};

export const getUrlParam = (
    paramName: string,
    params: string = window.location.search,
) => {
    let urlSearchParams = new URLSearchParams(params);
    return urlSearchParams.get(paramName);
};

export const addUrlParam = (
    paramName: string,
    paramVal: any,
    params: string = window.location.search,
) => {
    let urlSearchParams = new URLSearchParams(params);
    urlSearchParams.append(paramName, paramVal);
    window.history.replaceState({}, document.title, `${window.location.pathname}?${urlSearchParams}`);
};

export const updateUrlParam = (
    paramName: string,
    paramVal: any,
    params: string = window.location.search,
) => {
    let urlSearchParams = new URLSearchParams(params);
    urlSearchParams.set(paramName, paramVal);
    window.history.replaceState({}, document.title, `${window.location.pathname}?${urlSearchParams}`);
};

export const removeUrlParam = (
    paramName: string,
    params: string = window.location.search,
) => {
    let urlSearchParams = new URLSearchParams(params);
    urlSearchParams.delete(paramName);
    if (urlSearchParams.toString()) {
        window.history.replaceState({}, document.title, `${window.location.pathname}?${urlSearchParams}`);
    } else {
        window.history.replaceState({}, document.title, window.location.pathname);
    }
};
