import Ready from "@/utils/ready";
import {AppBus} from "@/const";
const selector = '[data-ref="itemsTreeMenu"]';

class ItemsTreeMenu {
    id: any = null;
    el: any = null;
    headEl: any = null;
    bodyEl: any = null;
    linksEl: any = null;
    imageEl: any = null;
    nameEl: any = null;
    toggled: boolean = false;
    constructor(element: HTMLElement) {
        this.el = element;
        this.id = this.el.getAttribute('data-ref-id');
        this.headEl = this.el.querySelector(`[data-ref="itemsTreeMenuHead"][data-ref-id="${this.id}"]`);
        this.bodyEl = this.el.querySelector(`[data-ref="itemsTreeMenuBody"][data-ref-id="${this.id}"]`);
        this.linksEl = this.el.querySelectorAll(`[data-ref="itemsTreeMenuItem"][data-ref-id="${this.id}"]`);
        this.imageEl = this.el.querySelector(`[data-ref="itemsTreeMenuImage"][data-ref-id="${this.id}"]`);
        this.nameEl = this.el.querySelector(`[data-ref="itemsTreeMenuName"][data-ref-id="${this.id}"]`);
        this.init();
    }

    private init() {
        this.hideActiveLink();
        this.addEventListeners();
    }

    private addEventListeners() {
        this.headEl.addEventListener('click', this.toggle.bind(this));
        [].forEach.call(this.linksEl, (linkEl: HTMLElement) => {
            linkEl.addEventListener('click', () => {
                this.toggle(linkEl);
                this.onCategoryChange(linkEl.dataset.propId, true);
            });
        });
        AppBus.$on('itemsTree:categoryChange', this.onCategoryChange.bind(this));
    }

    private onCategoryChange(categoryId: any, sendEvent: boolean = false) {
        const selectedEl = this.el.querySelector(`[data-ref="itemsTreeMenuItem"][data-ref-id="${this.id}"][data-prop-id="${categoryId}"]`);
        const name = selectedEl.dataset.propName;
        const image = selectedEl.dataset.propImage;
        this.imageEl.setAttribute("style", `background-image: url('${image}');`);
        this.nameEl.innerHTML = name;
        if (sendEvent) {
            AppBus.$emit('itemsTreeMenu:categoryChange', categoryId);
        }
    }

    private toggle(link?: any) {
        if (link.nodeType) {
            if (link.dataset && link.dataset.ref === 'itemsTreeMenuItem') {
                [].forEach.call(this.linksEl, (linkEl: HTMLElement) => {
                    linkEl.classList.remove('active');
                });
                link.classList.add('active');
            }
        }
        if (this.toggled) {
            this.toggled = false;
            this.headEl.classList.remove('itemsTree__navigation__mobile__select--active');
            this.bodyEl.style.maxHeight = "0";
        } else {
            this.toggled = true;
            this.headEl.classList.add('itemsTree__navigation__mobile__select--active');
            this.bodyEl.style.maxHeight = 'none';
        }
    }

    private hideActiveLink() {
        [].forEach.call(this.linksEl, (linkEl: HTMLElement) => {
            const linkText = linkEl.querySelector('.itemsTree__navigation__mobile__list__item__text');
            if (linkText && this.nameEl.innerHTML === linkText.innerHTML) {
                linkEl.classList.add('active');
            }
        });
    }
}

(() => {
    Ready.watch(selector, (element: HTMLElement) => {
        new ItemsTreeMenu(element);
    });
})();
