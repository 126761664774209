import Ready from "@/utils/ready";

const selector = '[data-ref="breadcrumb"]';

class Breadcrumb {
    breadcrumb: any;
    breadcrumbHeight: any;
    levels: any;
    firstLevel: any;
    firstSection: any;
    header: any;
    headerHeight: any;
    footer: any;
    footerHeight: any;
    isIE11: any;
    offset: number = 80;

    constructor(element: HTMLElement) {
        this.breadcrumb = element;
        this.breadcrumbHeight = this.breadcrumb.offsetHeight;
        this.levels = element.querySelectorAll('.breadcrumb__level');
        this.firstLevel = element.querySelector('.breadcrumb__level:first-of-type');
        this.firstSection = document.querySelector(`[data-scroll-point="history"]`);
        this.header = document.querySelector('[data-ref="header"]');
        this.headerHeight = this.header.offsetHeight;
        this.footer = document.querySelector('[data-ref="footer"]');
        this.footerHeight = this.footer.offsetHeight;
        this.isIE11 = !!(window as any).MSInputMethodContext && !!(document as any).documentMode;
        this.init();
        this.addEventListeners();
        this.checkWidth();
        this.setBreadcrumbWidth();
        window.addEventListener('resize', () => {
            this.checkWidth();
            this.setBreadcrumbWidth();
        });
        // setTimeout(() => {
        //     this.scrollTo();
        // }, 500);
    }

    init() {
        // const offset: any = window.getComputedStyle(this.firstSection, null).getPropertyValue('padding-top');
        this.firstLevel.classList.add('is-active');
        this.breadcrumb.style.top = this.firstSection.offsetTop + this.offset + 'px';
    }

    getBelowMin() {
        return document.documentElement.scrollTop >= this.firstSection.offsetTop - this.headerHeight;
    }

    getBetween() {
        return this.getBelowMin() && !this.getAboveMax();
    }

    getAboveMax() {
        return document.documentElement.scrollTop > document.documentElement.offsetHeight - this.footerHeight - (this.offset * 2) - this.headerHeight - this.breadcrumbHeight;
    }

    getBreadcrumbPositionMin() {
        return this.firstSection.offsetTop + this.offset;
    }

    getBreadcrumbPositionScroll() {
        return this.headerHeight + this.offset;
    }

    getBreadcrumbPositionMax() {
        return document.documentElement.offsetHeight - this.footerHeight - this.breadcrumbHeight - this.offset;
    }

    onFooterTabClick(e: any) {
        this.footerHeight = this.footer.offsetHeight;
        if (this.getBetween()) {
            this.breadcrumb.style.position = 'fixed';
            this.breadcrumb.style.top = this.getBreadcrumbPositionScroll() + 'px';
        }
        else {
            this.breadcrumb.style.position = 'absolute';
            this.breadcrumb.style.top = this.getBreadcrumbPositionMax() + 'px';
        }
    }

    onScroll() {
        // console.log(this.getBelowMin(), this.getAboveMax(), this.getBetween());
        if (this.getBetween()) {
            this.breadcrumb.style.position = 'fixed';
            this.breadcrumb.style.top = this.getBreadcrumbPositionScroll() + 'px';
        }
        else if (this.getAboveMax()) {
            this.breadcrumb.style.position = 'absolute';
            this.breadcrumb.style.top = this.getBreadcrumbPositionMax() + 'px';
        }
        else {
            this.breadcrumb.style.position = 'absolute';
            this.breadcrumb.style.top = this.getBreadcrumbPositionMin() + 'px';
        }
    }

    addEventListeners() {
        // document.addEventListener('scroll', () => {
        //     if (document.documentElement.scrollTop >= this.firstSection.offsetTop - this.headerHeight) {
        //         this.breadcrumb.style.position = 'fixed';
        //         this.breadcrumb.style.top = this.headerHeight + this.offset + 'px';
        //     }
        //     else {
        //         this.breadcrumb.style.position = 'absolute';
        //         this.breadcrumb.style.top = this.firstSection.offsetTop + this.offset + 'px';
        //     }
        // });
        document.addEventListener('DOMContentLoaded', () => {
            this.onScroll();
            const scope = this.getUrlParameter(window.location.href);
            if (scope !== '') {
                const elementScoped: any = document.querySelector(`[data-scroll-point="${scope}"]`);
                setTimeout(() => {
                    this.scrollTo(elementScoped);
                }, 500);
            }
        });

        [].forEach.call(this.footer.querySelectorAll('[data-ref="footerListItem"]'), (footerTab: any) => {
            footerTab.addEventListener('click', this.onFooterTabClick.bind(this));
        });
        document.addEventListener('scroll', this.onScroll.bind(this));

        [].forEach.call(this.levels, (level: any) => {
            const crumbs = level.querySelector('.breadcrumb__crumbs');
            const scope = crumbs.dataset.scope;
            const elementScoped: any = document.querySelector(`[data-scroll-point="${scope}"]`);
            crumbs.addEventListener('click', () => {
                if (elementScoped) {
                    this.scrollTo(elementScoped);
                    this.setURLParameter(window.location.href, 'target', scope);
                }
            });
            document.addEventListener('scroll', () => {
                const scroll = document.documentElement.scrollTop;
                const breadcrumbScroll = scroll + this.offset + this.headerHeight;
                if (breadcrumbScroll > (elementScoped.getBoundingClientRect().top + scroll)) {
                    this.selectLevel(level);
                }
            });
        })
    }

    selectLevel(el: any) {
        [].forEach.call(this.levels, (level: any) => {
            level.classList.remove('is-active');
        });
        el.classList.add('is-active');
    }

    checkWidth() {
        if (window.innerWidth >= 1820) {
            this.breadcrumb.style.display = 'block';
        }
        else {
            this.breadcrumb.style.display = 'none';
        }
    }

    setBreadcrumbWidth() {
        this.breadcrumb.style.maxWidth = (window.innerWidth - 1400) / 2 - 60 + 'px';
    }

    scrollTo(elementScoped: any) {
        if (elementScoped) {
            const y = (elementScoped.getBoundingClientRect().top + document.documentElement.scrollTop) - this.offset;
            if (this.isIE11) {
                window.scrollTo(0, y);
            } else {
                window.scrollTo({
                    top: y,
                    behavior: 'smooth'
                });
            }
        }
    }

    getUrlParameter(url: any) {
        const urlParam = url.substr(url.indexOf("=") + 1);
        return urlParam ? decodeURIComponent(urlParam) : '';
    }

    setURLParameter(url: any, key: any, value: any) {
        const encodedKey = encodeURIComponent(key);
        const encodedValue = encodeURIComponent(value);

        const baseUrl = url.split('?')[0];
        const newParam = encodedKey + '=' + encodedValue;
        let params = '?' + newParam;

        let urlQueryString = '';
        if (url.split('?')[1] !== undefined) {
            urlQueryString = '?' + url.split('?')[1];
        }

        // If the "search" string exists, then build params from it
        if (urlQueryString) {
            let updateRegex = new RegExp('([\?&])' + encodedKey + '=[^&]*');
            let removeRegex = new RegExp('([\?&])' + encodedKey + '=[^&;]+[&;]?');

            if (encodedValue === undefined || encodedValue === null || encodedValue === '') {
                params = urlQueryString.replace(removeRegex, "$1");
                params = params.replace(/[&;]$/, "");

            } else if (urlQueryString.match(updateRegex) !== null) { // If param exists already, update it
                params = urlQueryString.replace(updateRegex, "$1" + newParam);

            } else if (urlQueryString == '') { // If there are no query strings
                params = '?' + newParam;
            } else { // Otherwise, add it to end of query string
                params = urlQueryString + '&' + newParam;
            }
        }

        // If no parameter was set, we don't need the question mark
        params = params === '?' ? '' : params;

        window.history.pushState('', '', baseUrl + params);
    }
}

(() => {
    Ready.watch(selector, (element: HTMLElement) => {
        new Breadcrumb(element);
    });
})();
