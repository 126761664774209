import Ready from "@/utils/ready";

const selector = '[data-ref="popupJob"]';
class PopupJob {
    popup: any;
    popupClose: any;

    constructor(el: any) {
        this.popup = el;
        this.popupClose = el.querySelector(`[data-ref=popupJobClose]`);
        this.addEventListeners();
    }

    private addEventListeners() {
        this.popupClose.addEventListener('click', () => {
            this.popup.classList.toggle('popup-is-open');
            this.popup.classList.add('popup-is-closed');
            document.body.style.overflowY = 'auto';
        });
        const speculativeContactEl = document.querySelector('#speculativeApplication');
        if (speculativeContactEl) {
            speculativeContactEl.addEventListener('click', () => {
                this.openPopup();
                const applyJobSelect = (document as any).getElementById('apply_job_offer');
                if (applyJobSelect) {
                    applyJobSelect.value = null;
                }
            });
        }
    }

    private openPopup() {
        this.popup.classList.toggle('popup-is-open');
        this.popup.classList.remove('popup-is-closed');
        document.body.style.overflowY = 'hidden';
    }
}

(() => {
    Ready.watch(selector, (element: any) => {
        new PopupJob(element);
    });
})();
