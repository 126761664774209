













































































































































































import Ready from '@/utils/ready';
import {Component, Prop, Vue} from 'vue-property-decorator';
import axios from 'axios';
import {Main} from "element-ui";

@Component
export default class MainSlider extends Vue {
    private swiper: any = null;
    private slides: any[]|null = null;
    @Prop({default: ''}) private callToAction!: string;
    @Prop({default: 'en'}) private locale!: string;
    // private currentSlideNumber: number = 0;
    // private slideIntervalTime: number = 10000;
    // private slideInterval: any = null;
    // private setCurrentSlideNumber(value: number) {
    //     this.currentSlideNumber = value;
    //     clearInterval(this.slideInterval);
    //     this.setInterval();
    // }

    mounted() {
        axios.get('/home-slider/api')
            .then((response) => {
              this.slides = response.data.data.filter((elem: any) => {
                  if (elem.locale.includes(this.locale)) {
                    return true;
                  }
                  return false;
                }).map((elem: any) => elem)
                // this.setInterval();
                this.$nextTick(() => {
                    import('swiper/dist/js/swiper.js').then((Swiper) => {
                        this.swiper = new Swiper.default('[data-ref="swiper"]', {
                            autoplay: {
                                delay: 10000,
                                disableOnInteraction: false,
                            },
                            loop: true,
                            speed: 500,
                            pagination: {
                                el: '.swiper-pagination',
                                clickable: true,
                                renderBullet: (index: number, className: any) => {
                                    return `<li class="${className}"></li>`;
                                },
                            },
                        });
                    })
                })
            })
            .catch((err) => console.error(err));
    }

    // private setInterval() {
    //     this.slideInterval = setInterval(this.nextSlide, this.slideIntervalTime);
    // }

    // private nextSlide() {
    //     if (this.slides) {
    //         if (this.currentSlideNumber + 1 > this.slides.length - 1) {
    //             this.currentSlideNumber = 0;
    //         } else {
    //             this.currentSlideNumber += 1;
    //         }
    //     }
    // }

    // private get currentSlide() {
    //     if (this.slides && this.slides.length > 0 ) {
    //         return this.slides[this.currentSlideNumber];
    //     }
    //     return null;
    // }
}
