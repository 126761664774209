import '@/polyfills/WeakMap';
import '@/polyfills/MutationObserver';
import '@/polyfills/ArrayFrom';
import '@/polyfills/StartsWith';
import '@/polyfills/Find';
import '@/polyfills/ObjectFit';
import 'promise-polyfill/src/polyfill';

import Vue from 'vue';
import App from './App.vue';
import store from './store';
import './plugins/element.js';
import '@config/pristine.config.js';
import '@/js/scroller';
import '@/js/assets.ts';
import '@/js/zoho.ts';
import '@/js/inputCRM.ts';
import '@/js/front-page-slider.ts';
import '@/js/footer.ts';
import '@/js/header.ts';
import '@/js/card-slider.ts';
import '@/js/job-slider.ts';
import '@/js/image-picker.ts';
import '@/js/accordion.ts';
import '@/js/global-accordion.ts';
import '@/js/breadcrumb.ts';
import '@/js/clampify.ts';
import '@/js/contact.ts';
import '@/js/mainSlider';
import '@/js/jobOffer.ts';
import '@/js/popup.ts';
import '@/js/popupJob.ts';
import '@/js/formValidation.ts';
import '@/js/listMenu';
import '@/js/itemsTree';
import '@/js/itemsTreeMenu';
import '@/js/configurator';
import '@/js/inputFile';
import '@/js/shareButton';
import '@/js/unitToggler';
import '@/js/gallery';
import '@/js/insertFormReady';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'fontisto';
import '@/styles/main.scss';
import 'simplebar';
import 'simplebar/dist/simplebar.css';

const ElementQueries = require('css-element-queries/src/ElementQueries');
ElementQueries.listen();

Vue.config.productionTip = false;

// const FrontPageSlider = require('@/components/FrontPageSlider.vue').default;
// const getFrontPageSlider = document.querySelector('[data-ref=front-page-slider]');
// if (getFrontPageSlider) {
//     new Vue({
//         components: {
//             FrontPageSlider,
//         },
//         template: '<FrontPageSlider></FrontPageSlider>',
//     }).$mount('[data-ref=front-page-slider]');
// }
