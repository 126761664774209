import Ready from "@/utils/ready";

const setStorageMeasurementSystem = (unit = 'metric') => {
    localStorage.setItem('measurementSystem', unit);
    return unit
};

const selector = '[data-ref="unitToggler"]';
class UnitToggler {
  el: any;
  id: any;
  switchInput: any;
  metricContent: any;
  imperialContent: any;
  selectedUnit: any = localStorage.getItem('measurementSystem')
      ? localStorage.getItem('measurementSystem')
      : setStorageMeasurementSystem();

  constructor(el: any) {
    this.el = el;
    this.id = el.dataset.refId;
    this.switchInput = el.querySelector(`[data-ref="unitTogglerSwitchInput"][data-ref-id="${this.id}"]`);
    this.metricContent = el.querySelector(`[data-ref="unitTogglerMetricContent"][data-ref-id="${this.id}"]`);
    this.imperialContent = el.querySelector(`[data-ref="unitTogglerImperialContent"][data-ref-id="${this.id}"]`);
    this.init();
    this.addEventListeners();
  }

  init() {
    if (this.selectedUnit === 'imperial') {
      this.switchInput.setAttribute("checked", true);
    } else {
      this.switchInput.removeAttribute("checked");
    }
    this.setSelectedContent();
  }

  addEventListeners() {
    this.switchInput.addEventListener('change', () => {
      const selectUnitValue = this.switchInput.checked ? 'imperial' : 'metric';
      this.setSelectedContent(selectUnitValue);
    });
  }

  setSelectedContent(selectedUnit = this.selectedUnit) {
    if (selectedUnit === 'imperial') {
      this.metricContent.style.display = 'none';
      this.imperialContent.style.display = 'block';
    } else if (selectedUnit === 'metric') {
      this.metricContent.style.display = 'block';
      this.imperialContent.style.display = 'none';
    }
    setStorageMeasurementSystem(selectedUnit);
  }
}

(() => {
  Ready.watch(selector, (element: any) => {
    new UnitToggler(element);
  });
})();
