import Ready from "@/utils/ready";

const selector = `[data-ref="inputFile"]`;

class InputFile {
    id: any;
    el: HTMLElement;
    input: any;
    span: any;
    errorEl: any;
    initialText: any;

    constructor(el: HTMLElement) {
        this.el = el;
        this.id = this.el.dataset.refId;
        this.input = this.el.querySelector('input[type="file"]');
        this.span = this.el.querySelector('span');
        this.initialText = this.span.innerHTML;
        this.addEventListeners();
    }

    private addEventListeners() {
        this.input.addEventListener('change', (e: any) => {
            const uploadPath = e.target.value.split('\\').pop();
            this.span.innerHTML = uploadPath;
            if (uploadPath) {
                this.input.parentNode.classList.add('input--correct');
                this.input.parentNode.classList.remove('input--wrong');
            } else {
                this.span.innerHTML =  this.initialText;
                this.input.parentNode.classList.add('input--wrong');
                this.input.parentNode.classList.remove('input--correct');
            }
        })
    }
}

(() => {
    Ready.watch(selector, (element: any) => {
        new InputFile(element);
    });
})();

