import Ready from "@/utils/ready";
import Vue from 'vue';

const selector = '[data-ref="front-page-slider"]';
// const FrontPageSlider = require('@/components/FrontPageSlider.vue').default;
(() => {
  Ready.watch(selector, (element: any) => {
    import('@/components/FrontPageSlider.vue').then((FrontPageSlider: any) => {
      new Vue({
        components: {
          FrontPageSlider: FrontPageSlider.default,
        },
        template: `<FrontPageSlider lang="${element.dataset.propLang}"></FrontPageSlider>`
      }).$mount(element);
    });
  });
})();
