import Ready from "@/utils/ready";

const selector = '[data-ref="zoho"]';

(() => {
  Ready.watch(selector, (element: any) => {
    import('@/js/zoho-forms.ts').then((ZohoForm: any) => {
      new ZohoForm.default(element);
    })
  });
})();
