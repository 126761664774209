import Ready from '@/utils/ready';

const selector = '[data-ref="shareButton"]';

class shareButton {
    el: any;
    type: string;
    url: string;

    constructor(el: any) {
        this.el = el;
        this.type = el.dataset.type || '';
        this.url = el.dataset.url || '';
        this.init();
    }

    private init() {
        if (this.type && this.url) {
            this.el.addEventListener('click', () => {
                if (this.type === 'facebook') {
                    window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.url}`, 'facebook-share-dialog', 'width=800,height=600');
                } else if (this.type === 'twitter') {
                    window.open(`https://twitter.com/intent/tweet?url=${this.url}&via=Sofrigam`);
                } else if (this.type === 'linkedin') {
                    window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${this.url}`);
                }
                return false;
            })
        }
    }
}
(() => {
    Ready.watch(selector, (element: HTMLElement) => {
        new shareButton(element);
    });
})();
