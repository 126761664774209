// @ts-ignore
import Ready from '@/utils/ready';
import {AppBus} from "@/const";

const selector = '.js-job-slider';
export default class JobSlider {
  private readonly el: HTMLElement;
  private instance: any = null;
  constructor(elementOrSelector: any, options = {}) {
    if (!elementOrSelector) {
      throw new Error('Card Slider requires a Selector or a HTML Element.');
    }
    this.el = elementOrSelector;
    this.instanciate(this.el, options);
    this.addEventListeners();
  }

  private instanciate(elementOrSelector: any, options: any) {
    import('swiper/dist/js/swiper.js').then((Swiper) => {
      this.instance = new Swiper.default(elementOrSelector, {
        ...options,
        on: {
          slidePrevTransitionStart() {
            const activeSlideEl = elementOrSelector.querySelector('.swiper-slide-active');
            AppBus.$emit('changeJob', Number(activeSlideEl.getAttribute('data-id')));
          },
          slideNextTransitionStart() {
            const activeSlideEl = elementOrSelector.querySelector('.swiper-slide-active');
            AppBus.$emit('changeJob', Number(activeSlideEl.getAttribute('data-id')));
          }
        }
      });
    })
  }

  private addEventListeners() {
    AppBus.$on('setJobSliderIndex', (slideId: number) => {
      const targetSlide = this.el.querySelector(`.swiper-slide[data-id="${slideId}"]`);
      if (targetSlide) {
        const index = Array.from(this.el.querySelectorAll('.swiper-slide')).indexOf(targetSlide);
        this.instance.slideTo(index);
      }
    })
  }
}

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    const id = element.getAttribute('data-id') || null;
    // const option = element.getAttribute('data-option') ? JSON.parse((element.getAttribute('data-option') as any)) : {};
    return new JobSlider(element, {
      navigation: {
        nextEl: document.querySelector(`[data-next="${id}"]`),
        prevEl: document.querySelector(`[data-prev="${id}"]`),
        hideOnClick: true,
      },
      slidesPerView: 1,
      spaceBetween: 0,
      // ...option,
    });
  });
})();
