import Ready from "@/utils/ready";
import {AppBus} from "@/const";

const selector = `[data-ref="inputCRM"]`;

// First try

class InputCRM {
    id: any;
    el: HTMLElement;
    input: any;
    errorEl: any;

    constructor(el: HTMLElement) {
        this.el = el;
        this.id = this.el.dataset.refId;
        this.input = this.el.querySelector(`[data-ref="inputCRMInput"][data-ref-id="${this.id}"]`);
        this.errorEl = this.el.querySelector(`[data-ref="inputCRMError"][data-ref-id="${this.id}"]`);
        this.addEventListeners();
    }

    private addEventListeners() {
        const input: HTMLInputElement = this.input.querySelectorAll("input, select, checkbox, textarea")[0];
        if (input) {
            input.addEventListener('input', () => {
                this.errorEl.innerHTML = input.validationMessage;
                if (input.validity.valid) {
                    input.classList.remove('input--wrong');
                    input.classList.add('input--correct');
                } else {
                    input.classList.add('input--wrong');
                    input.classList.remove('input--correct');
                }
            });
            AppBus.$on('zohoForm:hasError', (requiredEl: any, errorEl: any) => {
                requiredEl.classList.add('input--wrong');
                errorEl.innerHTML = requiredEl.validationMessage;
            });
            AppBus.$on('zohoForm:isValid', (requiredEl: any) => {
                requiredEl.classList.add('input--correct');
            });
        }
    }
}

(() => {
    Ready.watch(selector, (element: any) => {
        new InputCRM(element);
    });
})();

