import MainSlider from "@/components/organisms/MainSlider.vue";
import Ready from '@/utils/ready';
import Vue from 'vue';

(() => {
    Ready.watch('[data-ref="mainSlider"]', (element: HTMLElement) => {
        new Vue({
            components: {
                MainSlider
            },
            template: `<MainSlider callToAction="${element.getAttribute('data-prop-call-to-action')}" locale="${element.dataset.propLocale}"/>`,
        }).$mount(element);
    });
})();
