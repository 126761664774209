import Ready from "@/utils/ready";

const selector = `[data-ref=popupJob]`;
const formToCheck = document.querySelector('[data-ref=popupJob]');

// class FormValidation {
//     el: any;
//     inputs: any;
//
//     constructor(el: any) {
//         this.el = el;
//         this.inputs = el.querySelectorAll('input');
//         // this.init();
//     }
//
//     init() {
//         [].forEach.call(this.inputs, (input: any) => {
//             input.addEventListener('change', (e: any) => {
//                 if (e.target.value) {
//                     input.classList.add('input--correct');
//                 } else {
//                     input.classList.add('input--wrong');
//                 }
//             })
//         });
//     }
//
//     resetClass(target: any) {
//         target.classList.remove('input--correct', 'input--correct');
//     }
// }

// First try

class FormValidation {
    form: any;
    formInputs: any;
    formLastName: any;
    formFirstName: any;
    formEmail: any;
    formSubject: any;
    formMessage: any;
    formError: any;
    formErrorLastName: any;
    formErrorFirstName: any;
    formErrorEmail: any;
    formErrorSubject: any;
    formErrorMessage: any;

    constructor(el: any) {
        this.form = el;
        this.formInputs = el.querySelectorAll('.js-input');
        this.formLastName = el.querySelector('#lastName');
        this.formFirstName = el.querySelector('#firstName');
        this.formEmail = el.querySelector('#mail');
        this.formSubject = el.querySelector('#subject');
        this.formMessage = el.querySelector('#message');
        this.formError = el.querySelector('.input-error');
        this.formErrorLastName = el.querySelector('.js-inputErrorLastName');
        this.formErrorFirstName = el.querySelector('.js-inputErrorFirstName');
        this.formErrorEmail = el.querySelector('.js-inputErrorEmail');
        this.formErrorSubject = el.querySelector('.js-inputErrorSubject');
        this.formErrorMessage = el.querySelector('.js-inputErrorMessage');
        this.init();
    }

    init() {
        this.formLastName.addEventListener("input", () => {
            if (!this.formLastName.validity.valueMissing) {
                this.formErrorLastName.innerHTML = "";
                this.formErrorLastName.className = "input-error";
                this.formLastName.className = "input input--correct";
            }
        }, false);
        this.formFirstName.addEventListener("input", () => {
            if (!this.formFirstName.validity.valueMissing) {
                this.formErrorFirstName.innerHTML = "";
                this.formErrorFirstName.className = "input-error";
                this.formFirstName.className = "input input--correct";
            }
        }, false);
        this.formEmail.addEventListener("input", () => {
            if (!this.formEmail.validity.valueMissing) {
                this.formErrorEmail.innerHTML = "";
                this.formErrorEmail.className = "input-error";
                this.formEmail.className = "input input--correct";
            }
        }, false);
        this.formSubject.addEventListener("input", () => {
            if (!this.formSubject.validity.valueMissing) {
                this.formErrorSubject.innerHTML = "";
                this.formErrorSubject.className = "input-error";
                this.formSubject.className = "input input--correct";
            }
        }, false);
        this.formMessage.addEventListener("input", () => {
            if (!this.formMessage.validity.valueMissing) {
                this.formErrorMessage.innerHTML = "";
                this.formErrorMessage.className = "input-error";
                this.formMessage.className = "textarea--correct";
            }
        }, false);
        this.form.addEventListener("submit", (event: any) => {
            if (this.formLastName.validity.valueMissing) {
                this.formErrorLastName.innerHTML = "* Vous avez oublié de remplir ce champ obligatoire";
                this.formErrorLastName.className = "input-error input-error--active";
                this.formLastName.className = "input input--wrong";
                event.preventDefault();
            }
            if (this.formFirstName.validity.valueMissing) {
                this.formErrorFirstName.innerHTML = "* Vous avez oublié de remplir ce champ obligatoire";
                this.formErrorFirstName.className = "input-error input-error--active";
                this.formFirstName.className = "input input--wrong";
                event.preventDefault();
            }
            if (this.formEmail.validity.valueMissing) {
                this.formErrorEmail.innerHTML = "* Vous avez oublié de remplir ce champ obligatoire";
                this.formErrorEmail.className = "input-error input-error--active";
                this.formEmail.className = "input input--wrong";
                event.preventDefault();
            }
            if (this.formSubject.validity.valueMissing) {
                this.formErrorSubject.innerHTML = "* Vous avez oublié de remplir ce champ obligatoire";
                this.formErrorSubject.className = "input-error input-error--active";
                this.formSubject.className = "input input--wrong";
                event.preventDefault();
            }
            if (this.formMessage.validity.valueMissing) {
                this.formErrorMessage.innerHTML = "* Vous avez oublié de remplir ce champ obligatoire";
                this.formErrorMessage.className = "input-error input-error--active";
                this.formMessage.className = "textarea--wrong";
                event.preventDefault();
            }
        }, false);
    }
}

(() => {
    Ready.watch(selector, (element: any) => {
        new FormValidation(element);
    });
})();
// Second try

// class FormValidation {
//     form: any;
//     formInputs: any;
//     formError: any;
//     formErrorMessage: any;
//
//     constructor(el: any) {
//         this.form = el;
//         this.formInputs = el.querySelectorAll('.input');
//         this.init();
//     }
//
//     init() {
//         function insertAfter(el :any, referenceNode: any) {
//             referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
//         }
//         this.formError = document.createElement('span');
//         this.formError.class = 'input-error';
//         this.formErrorMessage = document.createTextNode('* Vous avez oublié de remplir ce champ obligatoire');
//         this.formError.appendChild(this.formErrorMessage);
//
//         console.log(this.formError);
//
//         [].forEach.call(this.formInputs, (item: any) => {
//             item.addEventListener("input", (event: any) => {
//                 if (item.validity.valid) {
//                     item.className = "input input--right";
//                 }
//             }, false);
//         });
//         this.form.addEventListener("submit", (event: any) => {
//             [].forEach.call(this.formInputs, (item: any) => {
//                 if (!item.validity.valid) {
//                     item.className = "input input--wrong";
//                     insertAfter(this.formError, item);
//                     event.preventDefault();
//                 }
//             });
//         }, false);
//     }
// }

// Third try

// class FormValidation {
//     form: any;
//     formInputs: any;
//
//     constructor(el: any) {
//         this.form = el;
//         this.formInputs = el.querySelectorAll('.input');
//         this.init();
//     }
//
//     init() {
//         [].forEach.call(this.formInputs, (item: any) => {
//             item.addEventListener("input", (event: any) => {
//                 if (item.validity.valid) {
//                     item.querySelector('.input-error').innerHTML = "";
//                     item.className = "input input--right";
//                 }
//             }, false);
//         });
//         this.form.addEventListener("submit", (event: any) => {
//             [].forEach.call(this.formInputs, (item: any) => {
//                 if (!item.validity.valid) {
//                     item.querySelector('.input-error').innerHTML = "* Vous avez oublié de remplir ce champ obligatoire";
//                     item.className = "input input--wrong";
//                     event.preventDefault();
//                 }
//             });
//         }, false);
//     }
// }

// (() => {
//     Ready.watch(selector, (formToCheck: HTMLElement) => {
//         new FormValidation(formToCheck);
//     });
// })();
