// @ts-ignore
import Ready from '@/utils/ready';

const selector = '.js-card-slider';
export default class CardSlider {
    private instance: any = null;
    constructor(elementOrSelector: any, options = {}) {
        if (!elementOrSelector) {
            throw new Error('Card Slider requires a Selector or a HTML Element.');
        }
        this.instanciate(elementOrSelector, options);
    }

    private instanciate(elementOrSelector: any, options: any) {

        import('swiper/dist/js/swiper.js').then((Swiper) => {
            this.instance = new Swiper.default(elementOrSelector, {
                ...options,
            });
        })
    }
}

(() => {
    Ready.watch(selector, (element: HTMLElement) => {
        const id = element.getAttribute('data-id') || null;
        const option = element.getAttribute('data-option') ? JSON.parse((element.getAttribute('data-option') as any)) : {};
        return new CardSlider(element, {
            navigation: {
                nextEl: document.querySelector(`[data-next="${id}"]`),
                prevEl: document.querySelector(`[data-prev="${id}"]`),
                hideOnClick: true,
            },
            slidesPerView: 4,
            spaceBetween: 24,
            breakpoints: {
                1520: {
                    slidesPerView: 3,
                },
                1216: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 1,
                },
            },
            ...option,
        });
    });
})();
