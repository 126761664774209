const pristineConfig = {
    unit: {
        sm: '30px',
        md: '40px',
        base: '50px',
        lg: '75px',
    },
    screens: {
        sm: '576px',
        md: '768px',
        lg: '992px',
        xl: '1200px',
        '2xl': '1400px',
    },
    colors: {
        primary: '#EC632D',
        secondary: '#426179',
        tertiary: '#a5b7c4',
        gray: '#92A4B2',
        't-t-primary': '#212247',
        't-t-secondary': '#4EBCC2',
        't&t-primary': '#212247',
        't&t-secondary': '#4EBCC2',
        'secondary-dark': '#253845',
        'gray-light': '#E5EAED',
        'grey-light': '#E5EAED',
        'grey-lighter': '#F2F5F6',
        'green-dark': '#30424e',
        'green': '#61b34b',
        'red': '#e40000',
    },
    spacing: {
        none: '0',
        xxs: '2px',
        word: '4px',
        xs: '10px',
        sm: '15px',
        md: '20px',
        base: '25px',
        lg: '30px',
        xl: '40px',
        xxl: '50px',
        '2xl': '50px',
        '3xl': '75px',
        '4xl': '100px',
    },
    gutter: {
        base: '15px',
    },
    backgroundColor: {},
    backgroundPosition: {},
    backgroundSize: {},
    borderColor: {},
    borderRadius: {},
    borderWidth: {},
    boxShadow: {},
    container: {},
    cursor: {},
    fill: {},
    flex: {},
    flexGrow: {},
    flexShrink: {},
    fontFamily: {
        sans: [
            'Saira',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            '"Noto Sans"',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
            '"Noto Color Emoji"',
        ],
        serif: [
            '"Times New Roman"',
            'Times',
            'Georgia',
            'Cambria',
            'serif',
        ],
    },
    fontSize: {
        xs: '11px',
        sm: '13px',
        base: '15px',
        md: '18px',
        lg: '20px',
        xl: '22px',
        '2xl': '30px',
        '3xl': '50px',
    },
    fontWeight: {},
    height: {},
    inset: {},
    letterSpacing: {
        base: '0.25px',
        lg: '1px',
        xl: '2px'
    },
    lineHeight: {
        base: '1.2',
        lg: '1.5',
        xl: '1.8',
    },
    listStyleType: {},
    margin: {},
    maxHeight: {},
    maxWidth: {},
    minHeight: {},
    minWidth: {},
    objectPosition: {},
    opacity: {},
    order: {},
    padding: {},
    stroke: {},
    textColor: {},
    width: {},
    zIndex: {},
};

module.exports = pristineConfig;
