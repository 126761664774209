import Ready from '@/utils/ready';
import objectFitImages from 'object-fit-images';

const selector = '[data-ref="objectFit"]';

const ObjectFit = (el = null) => {
    objectFitImages(el);
};

(() => {
    Ready.watch(selector, (element) => {
        if (
            element
        ) {
            ObjectFit(element);
        }
    });
})();

export default ObjectFit;
