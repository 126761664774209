import Ready from "@/utils/ready";

const selector = '[data-ref-toggler="insertForm"]';

(() => {
    Ready.watch(selector, (element: any) => {
        import('@/js/insertForm').then((InsertForm: any) => {
            new InsertForm.default(element);
        })
    });
})();
