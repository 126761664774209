import Ready from '@/utils/ready';
import Swiper from 'swiper';

const selector = '[data-gallery]';

export default class Gallery {
    private el: HTMLElement;
    private galleryItemsEL: Element[];
    private galleryPopupEl: HTMLElement | null;
    private gallerySwiper: any = null;

    constructor(element: HTMLElement) {
        this.el = element;
        this.galleryPopupEl = document.querySelector(`[data-gallery-id="${this.el.dataset.gallery}"]`);
        this.galleryItemsEL = Array.from(this.el.querySelectorAll('[data-gallery-item]'));
        if (this.galleryPopupEl && this.galleryItemsEL.length) {
            this.init();
        }
    }

    private init() {
        this.galleryItemsEL.forEach((item: any, index: number): void => {
            this.createSlide(item);
            item.addEventListener('click', (e: any) => {
                this.gallerySwiper.slideTo(index);
                this.gallerySwiper.update();
            });
        });
        this.gallerySwiper = new Swiper((this.galleryPopupEl as any).querySelector('[data-gallery-container]'), {
            slidesPerView: 1,
            breakpoints: {},
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }

    private createSlide(item: any) {
        let wrapper = (this.galleryPopupEl as any).querySelector('[data-gallery-wrapper]');
        wrapper.insertAdjacentHTML('beforeend', `
           <div class="swiper-slide gallery__content__slide" style="background-image: url(${item.dataset.galleryItem})"></div> 
        `);
    }
}

(() => {
    Ready.watch(selector, (element: HTMLElement) => {
        new Gallery(element);
    });
})();
