import Ready from "@/utils/ready";
import {AppBus} from "@/const";

const selector = '[data-ref="contact"]';

class ContactForm {
    contact: any;
    id: any = null;
    contactButtons: any;
    contactForm: any;
    isIE11: any;
    pageWidth: number;
    // lineToForm: any;
    // lineToFormAfter: any;

    constructor(el: any) {
        this.contact = el;
        this.id = el.getAttribute('data-ref-id');
        this.contactButtons = el.querySelectorAll(`[data-ref="contactButton"][data-ref-id="${this.id}"]`);
        this.contactForm = el.querySelector(`[data-ref="contactForm"]`);
        this.isIE11 = !!(window as any).MSInputMethodContext && !!(document as any).documentMode;
        this.pageWidth = document.body.offsetWidth;
        this.addEventListeners();
    }

    private addEventListeners() {
        (window as any).addEventListener('resize', (e: any) => {
            this.pageWidth = document.body.offsetWidth;
        });
        [].forEach.call(this.contactButtons, (item: any) => {
            item.addEventListener('click', () => {
                const contactId = item.getAttribute('data-crm-param-contact-id');
                AppBus.$emit('zohoForm:setParams', {'contactId': contactId});
                if (!item.classList.contains('button--secondary')) {
                    this.resetSelection();
                    item.classList.add('button--secondary', 'line-to-form');
                    this.contactForm.classList.add('is-visible');
                    this.scrollToForm();
                } else {
                    item.classList.remove('button--secondary', 'line-to-form');
                    this.contactForm.classList.remove('is-visible');
                    this.contactForm.style.maxHeight = null;
                    if (this.pageWidth > 575) {
                        this.scrollToTop();
                    }
                }
            });
        })
    }

    private scrollToForm() {
        const y = (this.contactForm.getBoundingClientRect().top + document.documentElement.scrollTop) - 150;
        if (this.isIE11) {
            window.scrollTo(0, y);
        } else {
            window.scrollTo({
                top: y,
                behavior: 'smooth'
            });
        }
    }

    private scrollToTop() {
        if (this.isIE11) {
            window.scrollTo(0, 0);
        } else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    private resetSelection() {
        [].forEach.call(this.contactButtons, (button: any) => {
            button.classList.remove('button--secondary', 'line-to-form');
        });
    }
}

(() => {
    Ready.watch(selector, (element: any) => {
        new ContactForm(element);
    });
})();
