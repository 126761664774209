import 'clampify';
import Ready from "@/utils/ready";

const selector = '[data-ref="clampify"]';
declare const $clampify: any;

const init = (el: any = null) => {
        ($clampify as any)(el, {
            endsWith: '...',
            autoUpdate: true,
        });
};

(() => {
    Ready.watch(selector, (element: HTMLElement) => {
        init(element);
    });
})();
export default init;
